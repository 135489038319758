<template>
    <component :is="dynamicComponent || 'div'" />
</template>

<script>
export default {
    data() {
        return {
            dynamicComponent: null,
        };
    },
    async created() {
        this.loadComponent();
    },
    watch: {
        "$route"() {
            this.loadComponent();
        },
    },
    methods: {
        async loadComponent() {
            const componentLoader = this.$route.meta.component;
            if (componentLoader) {
                this.dynamicComponent = (await componentLoader()).default;
            }
        },
    },
};
</script>
